<template>
  <section class="node-paging">
    <el-pagination
      :current-page="currentPage"
      :page-size="pageSize"
      :page-sizes="pageSizes"
      :total="total"
      :layout="'pager'"
      @current-change="$emit('page', $event)"/>
  </section>
</template>

<script>
export default {
  name: 'VisualMapPagination',
  props: {
    total: {
      type: Number,
      required: true
    },
    currentPage: {
      type: Number,
      required: true
    },
    pageSize: {
      type: Number,
      required: true
    },
    pageSizes: {
      type: Array,
      required: true
    }
  }
}
</script>
