<template>
  <div class="flex-items toolbar-button">
    <el-tooltip content="Print as pdf">
      <i
        class="printer-icon"
        @click="print" />
    </el-tooltip>
    <el-tooltip content="Download as png">
      <i
        class="download-icon-dark"
        @click="download" />
    </el-tooltip>
    <el-tooltip content="Full Screen">
      <i
        :class="isFullScreen ? 'minimize-window-icon' : 'full-window-icon'"
        @click="$bus.$emit('changeScreenSize', isFullScreen = !isFullScreen)" />
    </el-tooltip>
  </div>
</template>

<script>
import html2canvas from 'html2canvas'
export default {
  name: 'VisualMapToolbar',
  props: {
    rootName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isFullScreen: false
    }
  },
  methods: {
    async getCanvas() {
      try {
        const getCanvas = await html2canvas(
          document.getElementById('infovis'),
          {
            background: '#FFF'
          }
        )
        return getCanvas
      } catch (err) {
        console.error(err)
      }
    },
    async print() {
      const getCanvas = await this.getCanvas()
      const dataUrl = getCanvas.toDataURL()
      const windowContent = `<!DOCTYPE html><html>
                <head>
                <title>ImportGenius Lighthouse (Beta)</title>
                <style>
                    @media print {
                    .imageContainer {
                        position: absolute;
                        top: 100px;
                        margin: auto;
                        width: 100%;
                    }
                    }
                    @media screen {
                    .imageContainer {
                        display: none;
                    }
                    }
                </style>
                </head>
                <body>
                <img class="imageContainer" src="${dataUrl}">
                </body></html>`
      const printWin = window.open(
        '',
        '',
        'width=' + screen.availWidth + ',height=' + screen.availHeight
      )
      printWin.document.open()
      printWin.document.write(windowContent)
      printWin.document.addEventListener(
        'load',
        function() {
          printWin.focus()
          printWin.print()
          printWin.close()
          printWin.document.close()
        },
        true
      )
    },
    async download() {
      try {
        const getCanvas = await this.getCanvas()
        const newData = getCanvas
          .toDataURL('image/png')
          .replace(/^data:image\/png/, 'data:application/octet-stream')
        let lnk = document.createElement('a'),
          e
        lnk.download = this.rootName + '.png'
        lnk.href = newData

        /// create a "fake" click-event to trigger the download
        if (document.createEvent) {
          e = document.createEvent('MouseEvents')
          e.initMouseEvent(
            'click',
            true,
            true,
            window,
            0,
            0,
            0,
            0,
            0,
            false,
            false,
            false,
            false,
            0,
            null
          )
          lnk.dispatchEvent(e)
        } else if (lnk.fireEvent) {
          lnk.fireEvent('onclick')
        }
      } catch (err) {
        console.error(err)
      }
    }
  }
}
</script>
