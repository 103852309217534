<template>
  <div
    v-loading="$actions.pending('company/visualmap/fetchVisualMappingData')"
    :class="screenSize"
    class="visual-map-container">
    <visual-map-graph :nodes="nodes"/>
    <div class="flex-item vmap-detail-section">
      <visual-map-root-node
        :main-root="mainRoot"
        :root-node="selectedNode"/>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import VisualMapGraph from '~/components/company/visual-map/VisualMapGraph'
import VisualMapRootNode from '~/components/company/visual-map/VisualMapRootNode'

export default {
  middleware: [
    'visualMappingSubscriptionIsRequired',
    // as temporary solution to bugs appearing when going to the visualmap page
    // while it is not ready yet, user will be redirected to the overview page
    ({ route, redirect }) => {
      const companyId = route.params.id
      if (route.fullPath.includes('/visualmap')) {
        return redirect(`/company/${companyId}/overview`)
      }
    }
  ],
  name: 'VisualMapPage',
  components: {
    VisualMapGraph,
    VisualMapRootNode
  },
  data() {
    return {
      screenSize: ''
    }
  },
  computed: {
    ...mapState('company/visualmap', ['nodes', 'mainRoot', 'selectedNode']),
    ...mapState('userSubscriptions', ['userHasVisualMappingSubscription'])
  },
  mounted() {
    this.$bus.$on('changeScreenSize', this.changeScreenSize)
  },
  destroyed() {
    this.$bus.$off('changeScreenSize', this.changeScreenSize)
  },
  methods: {
    changeScreenSize(isFullScreen) {
      this.screenSize = isFullScreen ? 'full-screen' : ''
    }
  }
}
</script>
<style src="~/assets/scss/sections/company/vmap.scss" lang="scss" />
