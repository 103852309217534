<template>
  <div
    v-if="node"
    class="popover-wrapper">
    <div class="popover-title">{{ nodeName }}
      <div class="el-popover el-popper visual-map-popper with-footer">
        <div class="popover-content">
          <div class="pop-over-default">
            <div class="flex-card full-details">
              <div class="card-main">
                <section class="flex-container cname">
                  <div class="flex-items">
                    <h2>{{ nodeName }}</h2>
                  </div>
                </section>
                <section class="flex-container company-address">
                  <p>{{ node.data.address }}</p>
                </section>
                <section class="flex-container company-stats top-border">
                  <div class="flex-items stat-container">
                    <span class="stat-count">{{ node.data.connectionCount }}</span>
                    <span>Connections</span>
                  </div>
                  <div class="flex-items stat-container">
                    <span class="stat-count">{{ node.data.shipmentCount }}</span>
                    <span>Shipments</span>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="popoverButtons"
          class="popover-footer flex-container"
          v-html="popoverButtons"/>
        <div
          id="popper-arrow"
          x-arrow
          class="popper__arrow"
          style="margin-left:-6px"/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VisualMapPopover',
  props: {
    node: {
      type: Object,
      required: true
    },
    nodeName: {
      type: String,
      default: ''
    },
    companyId: {
      type: String,
      default: ''
    },
    popoverButtons: {
      type: String,
      default: ''
    }
  }
}
</script>
