<template>
  <div
    id="center-container"
    class="flex-item vmap-section">
    <div
      id="infovis"
      ref="infovis"
      class="vmap" />
    <div class="flex-container vm-toolbar">
      <visual-map-toolbar :root-name="nodes.name ? nodes.name : '-Not Available-'"/>
      <div
        class="flex-items vm-legend">
        <div class="legend">
          <span class="legend-item">
            <label class="circle blue-circle" />Importer
          </span>
          <span class="legend-item">
            <label class="circle green-circle" />Supplier
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapMutations, mapActions, mapState } from 'vuex'
import { isEqual } from 'lodash'
import VisualMapToolbar from '~/components/company/visual-map/VisualMapToolbar'
import VisualMapPopover from '~/components/company/visual-map/VisualMapPopover'

export default {
  name: 'VisualMapGraph',
  components: {
    VisualMapToolbar
  },
  props: {
    nodes: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      selectedNodeId: '',
      activateOnClick: false,
      ht: null,
      popoverButtons: ''
    }
  },
  computed: {
    ...mapState('company/visualmap', ['mainRoot', 'selectedNode', 'clicked']),
    ...mapState('company', ['companyId'])
  },
  watch: {
    nodes(newVal) {
      //load JSON data.
      this.ht.loadJSON(newVal)
      //trigger animation

      this.ht.graph.eachNode(function(n) {
        let pos = n.getPos()
        pos.setc(-300, -300)
      })

      this.ht.compute('end')
      this.ht.fx.animate({
        modes: ['polar'],
        duration: 2000
      })
      //end
      this.ht.controller.onComplete()
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeCanvas)
  },
  mounted() {
    this.$nextTick(() => {
      this.initializeVisualMap()
      window.addEventListener('resize', this.resizeCanvas, { passive: true })
    })
  },
  methods: {
    ...mapActions('company/visualmap', ['setSelectedNode', 'initialize']),
    ...mapMutations('company/visualmap', ['setCurrentPage', 'setClicked']),
    resizeCanvas() {
      var canvas = this.ht.canvas

      canvas.resize(
        this.$refs.infovis.clientWidth,
        this.$refs.infovis.clientHeight
      )
      canvas.scale(1.5, 1.5)
    },
    initializeVisualMap() {
      const self = this
      //init Radial Graph
      this.ht = new window.$jit.RGraph({
        //id of the visualization container
        injectInto: 'infovis',
        //Change node and edge styles such as
        //color, width and dimensions.
        Node: {
          dim: 5,
          overridable: true
        },
        Edge: {
          lineWidth: 0.5,
          color: '#8A94A3'
        },
        Navigation: {
          enable: true,
          panning: true,
          zooming: 10
        },
        Events: {
          enable: true,
          onClick: function(node, eventInfo, e) {
            if (
              e.target.className === 'popover-wrapper' ||
              e.target.className === 'popover-title' ||
              e.target.className === 'node'
            ) {
              if (node.id === self.selectedNode.id) {
                self.ht.onClick(self.nodes.id, {
                  onComplete: function() {
                    self.setSelectedNode(self.nodes)
                  }
                })
                return
              }
              self.ht.onClick(node.id, {
                onComplete: function() {
                  self.setSelectedNode(node)
                }
              })
              self.setCurrentPage(1)
            }

            if (e.target.id === 'previewNode') {
              self.ht.onClick(node.id, {
                onComplete: function() {
                  self.setSelectedNode(node)
                }
              })
            }
            if (e.target.id === 'viewVisualMapProfile') {
              const link = `/company/${node.id}/visualmap`
              self.setCurrentPage(1)
              self.viewProfile(link)
            }
          },
          onMouseEnter: function(node, eventInfo, e) {
            if (
              e.target.className === 'popover-wrapper' ||
              e.target.className === 'popover-title' ||
              e.target.className === 'node'
            ) {
              const popover =
                e.target.className === 'popover-wrapper'
                  ? e.srcElement.children[0].children[0]
                  : e.target.className === 'node'
                    ? e.srcElement.children[0].children[0].children[0]
                    : e.srcElement.children[0]
              const containerOffset = document
                .getElementById('infovis-canvaswidget')
                .getBoundingClientRect()
              const popoverOffText = e.srcElement.getBoundingClientRect()
              const popoverOffset = popover.getBoundingClientRect()

              if (
                popoverOffText.top + popoverOffset.height >
                  containerOffset.top &&
                popoverOffText.bottom + popoverOffset.height <
                  containerOffset.bottom
              ) {
                // popover on bottom
                popover.setAttribute('x-placement', 'bottom')
                popover.style.bottom = null
                return
              }
              // popover on top
              popover.setAttribute('x-placement', 'top')
              const arrow = popover.children.length
              const arrowElement = popover.children[arrow - 1]
              arrowElement.removeAttribute('class')
              const arrowColor = self.popoverButtons
                ? 'popper-arrow-gray'
                : 'popper-arrow-white'

              arrowElement.classList.add('popper__arrow')
              arrowElement.classList.add(arrowColor)

              if (e.srcElement.children[0].offsetHeight === 24) {
                popover.style.bottom = '2rem'
                return
              }

              popover.style.bottom = '1.25rem'
            }
          }
        },
        //Attach event handlers and add text to the
        //labels. This method is only triggered on label
        //creation
        onCreateLabel: function(domElement, node) {
          if (Object.keys(node.data).length) {
            let nodeName = node.name ? node.name : '-NOT AVAILABLE-'
            self.popoverButtons = ''

            if (nodeName && nodeName !== '-NOT AVAILABLE-') {
              self.popoverButtons =
                '<button class="btn btn-default btn-primary" id="previewNode">Preview</button>'

              if (node.id !== self.companyId) {
                self.popoverButtons +=
                  '<a class="btn btn-default btn-text" id="viewVisualMapProfile">View Visual Map Profile</a>'
              }
            }

            const visualMapPopover = Vue.extend(VisualMapPopover)
            const popover = new visualMapPopover({
              propsData: {
                node: node,
                nodeName: nodeName,
                companyId: self.companyId,
                popoverButtons: self.popoverButtons
              }
            }).$mount()

            domElement.appendChild(popover.$el)
          }
        },
        //Change node styles when labels are placed
        //or moved.
        onPlaceLabel: function(domElement, node) {
          if (
            Object.keys(node.data).length &&
            domElement.children[0].firstElementChild.style
          ) {
            var style = domElement.style
            let titleStyle = domElement.children[0].firstElementChild.style
            style.display = ''
            style.cursor = 'pointer'
            if (node._depth === 0) {
              style.fontSize = '12px'
              style.color = '#48596F'
              titleStyle.fontWeight = '600'
            } else if (node._depth === 1) {
              style.fontSize = '10px'
              style.color = '#48596F'
              titleStyle.fontWeight = '400'
            } else {
              style.fontSize = '0.667em'
              style.color = '#8A94A3'
            }

            var left = parseInt(style.left)
            var w = domElement.offsetWidth
            style.left = left - w / 2 + 'px'
          }
        }
      })
      //load JSON data.
      this.ht.loadJSON(this.nodes)
      //trigger animation

      //compute positions and plot.
      this.ht.canvas.scale(1.4, 1.4)

      this.ht.graph.eachNode(function(n) {
        let pos = n.getPos()
        pos.setc(-300, -300)
      })

      if (!isEqual(this.mainRoot, this.selectedNode)) {
        this.ht.refresh()
        this.ht.onClick(this.selectedNode.id, { duration: 0 })
        return
      }

      this.ht.compute('end')
      this.ht.fx.animate({
        modes: ['polar'],
        duration: 2000
      })
      //end
      this.ht.controller.onComplete()
    },
    viewProfile(link) {
      this.$router.push(link)
    }
  }
}
</script>
