<template>
  <div
    v-if="Object.keys(rootNode).length"
    class="root-company">
    <div class="card-base flex-card full-details with-footer">
      <div class="card-main">
        <section class="flex-container cname">
          <div class="flex-items">
            <h2>{{ rootNode.name ? rootNode.name : '-NOT AVAILABLE-' }}</h2>
            <div
              class="tab">
              <a
                v-for="tab in tabsList"
                :key="tab.name"
                :class="{ active: tab.name === traderType }"
                @click="selectTraderType(tab.name)">{{ tab.text }}</a>
            </div>
            <!-- <div
              v-if="rootNode.name && rootNode.name !== '-NOT AVAILABLE-'"
              class="flex-container align-items center">
              <img
                :src="require(`~/assets/img/flag/${rootNode.data.flag.toLowerCase()}.svg`)"
                alt="USA"
                class="flag">
              <span class="country-name"> {{ rootNode.data.country }}</span>
            </div> -->
          </div>
        </section>
        <section class="flex-container company-address">
          <p>{{ rootNode.data.address }}</p>
        </section>
        <section class="flex-container company-stats top-border">
          <div class="flex-items stat-container">
            <span class="stat-count">{{ rootNode.data.connectionCount }}</span>
            <span>Connections</span>
          </div>
          <div class="flex-items stat-container">
            <span class="stat-count">{{ rootNode.data.shipmentCount }}</span>
            <span>Shipments</span>
          </div>
        </section>
      </div>
      <div
        v-if="!sameNode && rootNode.name !== '-NOT AVAILABLE-' && rootNode.name"
        class="flex-container card-footer">
        <nuxt-link
          :to="`/company/${rootNode.id}/visualmap`"
          class="btn btn-default btn-text">View Visual Map Profile</nuxt-link>
      </div>
    </div>
    <sub>Displaying {{ childrenIndex.from }} - {{ childrenIndex.to }} of {{ rootNode.data.connectionCount }} connections</sub>
    <visual-map-pagination
      :current-page="currentPage"
      :total="rootNode.data.connectionCount"
      :page-sizes="pageSize"
      :page-size="5"
      @page="changeCurrentPage"/>
  </div>
</template>
<script>
import { isEqual } from 'lodash'
import { mapState, mapActions, mapMutations } from 'vuex'
import VisualMapPagination from '~/components/company/visual-map/VisualMapPagination'

export default {
  name: 'VisualMapRootNode',
  filters: {
    isEqual
  },
  components: {
    VisualMapPagination
  },
  props: {
    mainRoot: {
      type: Object,
      required: true
    },
    rootNode: {
      type: Object,
      required: true
    },
    pageSize: {
      type: Array,
      default() {
        return [5, 10, 15, 20, 100]
      }
    }
  },
  computed: {
    ...mapState('company/visualmap', [
      'traderType',
      'traderTypes',
      'currentPage'
    ]),
    ...mapState('company', ['companyId']),
    childrenIndex() {
      const index = (this.currentPage - 1) * 5
      return {
        from: index + 1,
        to: index + this.rootNode.children.length
      }
    },
    sameNode() {
      return isEqual(this.mainRoot.id, this.rootNode.id)
    },
    tabsList() {
      return [
        { text: 'As Importer', name: 'consignee' },
        { text: 'As Supplier', name: 'shipper' }
      ].filter(
        item =>
          (item.name === 'consignee' && this.traderTypes.includes(item.name)) ||
          (item.name === 'shipper' && this.traderTypes.includes(item.name))
      )
    }
  },
  methods: {
    ...mapActions('company/visualmap', ['setTraderType', 'setCurrentPage']),
    ...mapMutations('company/visualmap', ['setTraderType']),
    changeCurrentPage(page) {
      const companyId = this.$route.params.id
      if (companyId === this.rootNode.id) {
        this.setCurrentPage(page)
        return
      } else if (page > 1) {
        this.$store.commit('company/visualmap/setCurrentPage', page)
        this.$router.push(`/company/${this.rootNode.id}/visualmap`)
      }
    },
    resetRootNode() {
      this.$bus.$emit('resetRootNode')
    },
    selectTraderType(traderType) {
      this.setTraderType(traderType)
      this.setCurrentPage(1)
    }
  }
}
</script>
